var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Grid", {
        attrs: {
          service: _vm.service,
          route_name: "classroom_configs",
          order_column: "created_at",
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c("vs-th", { key: "name", attrs: { "sort-key": "name" } }, [
                  _vm._v(_vm._s(_vm.$t("classroom.name"))),
                ]),
                _vm.isAdmin()
                  ? _c(
                      "vs-th",
                      {
                        key: "institution_name",
                        attrs: { "sort-key": "institution_name" },
                      },
                      [_vm._v(_vm._s(_vm.$t("classroom.institution")))]
                    )
                  : _vm._e(),
                _c("vs-th", { key: "total", attrs: { "sort-key": "total" } }, [
                  _vm._v(_vm._s(_vm.$t("classroom.total"))),
                ]),
                _c(
                  "vs-th",
                  {
                    key: "quantity_per_room",
                    attrs: { "sort-key": "quantity_per_room" },
                  },
                  [_vm._v(_vm._s(_vm.$t("classroom.quantity_per_room")))]
                ),
                _c("vs-th", { key: "quantity_total" }, [
                  _vm._v(_vm._s(_vm.$t("classroom.quantity_total"))),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                    ]),
                    _c("vs-td", { key: "name", attrs: { width: "25%" } }, [
                      _c("span", [_vm._v(" " + _vm._s(tr.name))]),
                    ]),
                    _vm.isAdmin()
                      ? _c(
                          "vs-td",
                          { key: "institution_name", attrs: { width: "20%" } },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(tr.institution.name)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c("vs-td", { key: "total", attrs: { width: "10%" } }, [
                      _c("span", [_vm._v(" " + _vm._s(tr.total))]),
                    ]),
                    _c(
                      "vs-td",
                      { key: "quantity_per_room", attrs: { width: "15%" } },
                      [_c("span", [_vm._v(" " + _vm._s(tr.quantity_per_room))])]
                    ),
                    _c(
                      "vs-td",
                      { key: "quantity_total", attrs: { width: "15%" } },
                      [_c("span", [_vm._v(" " + _vm._s(tr.quantity_total))])]
                    ),
                    _c("vs-td", { attrs: { width: "10%" } }, [
                      _c(
                        "span",
                        { staticClass: "flex flex-row justify-center gap-1" },
                        [
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "classrooms.monitoring_panel",
                                expression: "'classrooms.monitoring_panel'",
                              },
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.top",
                                value: {
                                  content: "Abrir Painel de Monitoramento",
                                  delay: { show: 1000, hide: 100 },
                                },
                                expression:
                                  "{content: 'Abrir Painel de Monitoramento',delay: { show: 1000, hide: 100 }}",
                                modifiers: { top: true },
                              },
                            ],
                            attrs: {
                              icon: "EyeIcon",
                              svgClasses:
                                "h-5 w-5 mb-1 mr-3 stroke-current text-success",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openMonitoringPanel(
                                  props.data[indextr].id
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }